import React from 'react'
import classes from './FullWidthSection.module.scss'
import Markdown from 'markdown-to-jsx';
import Typography from '../../../../../remitbee/components/typography/Typography';
import theme from '../../../../../remitbee/theme/Theme';

interface TableProps {
  columns: Array<any>;
  data: Array<any>;
  columnsBackground?: string;
  isEvenColor?: boolean;
}

const Table:React.FC<TableProps> = ({ columns, data , columnsBackground, isEvenColor = false}) => {
    const columnKeys = Object.keys(columns[0]);

  return (
    <div className={classes["rb-table-container"]} >
    <table className={classes["rb-custom-table"]}>
    <thead>
          <tr >
            {columnKeys?.map((key, index) => (
              <th key={index} style={{backgroundColor: columnsBackground && columnsBackground}}>
                {typeof columns[0][key] === 'string' ? <Typography variant='textXl' weight='semibold' color={columnsBackground ? theme.palette.background.white :theme.palette.primary.navy}>{<Markdown children={columns[0][key]} />}</Typography> : columns[0][key]}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((row, rowIndex) => (
            <tr key={rowIndex} style={{backgroundColor: rowIndex % 2 === 1 && isEvenColor && theme.palette.background.lightGray}}>
              {columnKeys?.map((key, colIndex) => (
                <td key={colIndex}><Typography variant='textMd' weight='medium'>{typeof row[key] === 'string' ?  <Markdown children={row[key]} /> : row[key]}</Typography></td>
              ))}
            </tr>
          ))}
        </tbody>
    </table>
  </div>
  )
}

export default Table;