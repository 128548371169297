import React from 'react'

const Placeholder = ({ height = 20, width = 20, color = '#FFFFFF' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill="none">
            <circle cx="10" cy="10" r="6.75" stroke={color} strokeWidth="1.5"/>
        </svg>
    )
}

export default Placeholder