import React from 'react'
import Box from '../../../../../remitbee/components/box/Box'
import classes from './FullWidthSection.module.scss'
import Typography from '../../../../../remitbee/components/typography/Typography'
import theme from '../../../../../remitbee/theme/Theme'
import Flag from '../../../../../remitbee/components/flag/Flag'

const IBANExample = () => {

  const data = [
    {
        title: 'GB',
        text: 'Country Code'
    },
    {
        title: '14',
        text: 'Check Digit'
    },
    {
        title: 'WXYZ',
        text: 'Bank Code'
    },
    {
        title: '205623',
        text: 'Branch Code'
    },
    {
        title: '25648978',
        text: 'Account Number'
    }
  ]  

  const HelperText = (item) => {
    return (
        <div className={classes['rb-helperText-iban']}>
        <Typography variant='text2XL' weight='bold' color={theme.palette.primary.navy}>{item?.title}</Typography>
        <Typography variant='textSm'>{item?.text}</Typography>
       </div>
    )
  }


  return (
    <Box>
        <div className={classes['rb-iban-example']}>
            <div className={classes['rb-iban-header']}>
                <Typography variant='text2XL' weight='bold' color={theme.palette.primary.navy}>Example of United Kingdom IBAN</Typography>
                <Flag code='GB' width={32} height={22} />
            </div>
            <div className={classes['rb-helperText-container']}>
              {data.map((item, index) => <HelperText key={index} {...item} />)}
            </div>
        </div>
    </Box>
  )
}

export default IBANExample