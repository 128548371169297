import React from 'react'
import ArrowDown from '../../icons/ArrowDown';
import ArrowUp from '../../icons/ArrowUp';
import Typography from '../typography/Typography';
import classes from './DropDown.module.scss';
import Box from '../box/Box';

type DropDownFieldProps = {
  /**
   * ID should be a unique character
   */
  id: string;
  /**
   * Label can be `string` or a `element`
   */
  label?: string | React.ReactNode;
  /**
   * Indicates the field as a `required` one
   */
  required?: boolean;
  /**
   * Indicates the selected item from the list
   */
  selectedItem?: string | React.ReactNode;
  defaultSelectedItem?: string | React.ReactNode;
  /**
   * Helper text can be a `string` or `element`
   */
  helperText?: string | React.ReactNode;
  /**
   * If error, needs to pass `true`
   */
  error?: string;
  placeholder?: string;
  /**
   * Pass `true`, if dropDownList to open
   */
  dropListOpen: boolean;
  /**
   * Handles the state change of dropDownList, Recommend to use `useState`
   */
  setDropListOpen?: any;
  /**
   * Pass `true`, if the field must be disabled
   */
  disabled?: boolean;
  style?: object;
  componentRef?: any;
  className?: any;
  selectable?: boolean;
  withOutDropdown?: boolean;
  displayErrorMessage?: boolean;
  multiSelect?: boolean;
}


/**
 * Create component in below format.
 * @example <ParentContainer>
 *    <DropDownField {...props} /><DropDownList {...props} />
 * </ParentContainer>
 * @param {YourComponentProps} `const [dropLisOpen,setDropListOpen] = useState(false)` - Common props to be passed for both child at same time
 */
const DropDownField: React.FC<DropDownFieldProps> = ({
  id,
  componentRef,
  disabled = false,
  selectedItem,
  defaultSelectedItem,
  label,
  required,
  helperText,
  error,
  placeholder,
  dropListOpen,
  setDropListOpen,
  withOutDropdown=false,
  className,
  selectable = true,
  displayErrorMessage = true,
  multiSelect,
  ...rest
}) => {  
  const selected = (selectedItem || defaultSelectedItem) ? true : false;

  const handleMenu = () => {
    if (disabled || !selectable) return;
    if (setDropListOpen) setDropListOpen(!dropListOpen);
  }

  return (
    <>
      <div ref={componentRef} className={classes[`rb-dropdown-container`]} id={id} onClick={handleMenu} {...rest}>
        <div className={classes[`rb-dropdown-field-wrapper`]}>
          {label && <Typography variant='body2' className={classes['rb-label-box']}>
            <Box display='flex'>
              <Box flexGrow={1}>
                {label}
                {required && <span className={classes['rb-dropdown-required']} />}
              </Box>
              {/* {hintText && <Tooltip
                arrow
                title={<div>{hintText}</div>}
                leaveDelay={100}
                placement={hintPosition as any}
                classes={{ tooltip: tooltip, arrow: tooltipArrow }}
              >
                <img className={classes['rb-questionMark']} src={InfoIcon} />
              </Tooltip>} */}
            </Box>
          </Typography>}
          <button id={id} type='button' className={`
            ${classes[`rb-dropdown-field`]}
            ${disabled ? classes[`rb-dropdown-field-disabled`] : ''}
            ${selected ? classes[`rb-dropdown-field-selected`] : ''}
            ${error ? classes[`rb-dropdown-field-error`] : ''}
            ${className}
         `}>
            <div className={classes[`rb-dropdown-field-textBox`]}>
              {((!selectedItem && !defaultSelectedItem) && placeholder) && <p className={classes[`rb-dropdown-placeholder`]}>{placeholder}</p>}
              {typeof selectedItem === 'string' ?
                <div className={classes[`rb-dropdown-field-content`]} dangerouslySetInnerHTML={{ __html: selectedItem }} />
                : <div>{(selectedItem || defaultSelectedItem)}</div>}
            </div>
              {!withOutDropdown ? !dropListOpen ? 
                <ArrowDown width={24} height={24} color={disabled? "#626680" :"#1160F7"} />
                : <ArrowUp width={24} height={24} color={disabled? "#626680" : "#1160F7"} /> : <></>}
          </button>
        </div>
        {helperText && !error && <Typography variant='body3' id={`${id}-dropdown-helper-text`} className={classes['rb-subText']}>{helperText}</Typography>}
        {error && displayErrorMessage && <Typography variant='body3' id={`${id}-dropdown-error-text`} className={classes['rb-subText-error']}>{error}</Typography>}
      </div>
    </>
  )
}

export default DropDownField