import React from 'react';
import classes from './Flag.module.scss';

type FlagProps = {
    code?: string;
    className?: any;
    width?: number;
    height?: number;
}

const Flag: React.FC<FlagProps> = ({
    code = 'CA',
    className,
    width,
    height
}) => {

    return code ? <img
        alt={code}
        src={`${process.env.AZURE_COMMON_STORAGE_ENDPOINT}/country-flags/${code}.svg`}
        className={`${classes['rb-flag-root']} ${className}`}
        style={{ width, height }}
    /> : null;
}

export default Flag;
