import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import arraySupport from 'dayjs/plugin/arraySupport';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import advancedFormat from 'dayjs/plugin/advancedFormat'

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(arraySupport);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(advancedFormat);


// Last parameter is to keep the value that came on start_time. If is false, the value will be updated accordingly with the timezone GMT.
// moment(start_time, 'hh:mma').tz('America/Toronto', true)

export const currentTimeIsBetweenTwoTimes = (start_time, end_time) => {
    let startTime = dayjs(start_time, 'hh:mma').tz('America/Toronto', true)
    let endTime = dayjs(end_time, 'hh:mma').tz('America/Toronto', true)
    const currentTime = dayjs().tz('America/Toronto')
    
    startTime = dayjs([currentTime.year(), currentTime.month(), currentTime.date(), startTime.hour(), startTime.minute(), 0]).tz('America/Toronto', true);
    endTime = dayjs([currentTime.year(), currentTime.month(), currentTime.date(), endTime.hour(), endTime.minute(), 0]).tz('America/Toronto', true);
    let isTimeBetween = false;

    if (startTime.isBefore(endTime)) isTimeBetween = currentTime.isBetween(startTime, endTime, null, '[]')
    else {
        let beforeMidnight = dayjs('11:59 PM', 'hh:mma').tz('America/Toronto', true)
        let afterMidnight = dayjs('12:00 AM', 'hh:mma').tz('America/Toronto', true)

        beforeMidnight = dayjs([currentTime.year(), currentTime.month(), currentTime.date(), beforeMidnight.hour(), beforeMidnight.minute(), 0]).tz('America/Toronto', true);
        afterMidnight = dayjs([currentTime.year(), currentTime.month(), currentTime.date(), afterMidnight.hour(), afterMidnight.minute(), 0]).tz('America/Toronto', true);

        isTimeBetween = currentTime.isBetween(startTime, beforeMidnight, null, '[]')
            || currentTime.isBetween(afterMidnight, endTime, null, '[]')
    }

    return isTimeBetween
}

export const maximumCustomerAge = () => dayjs().subtract(14, 'year').format('YYYY-MM-DD')

/**
 * This methods receives the America/Toronto date from the backend and parses to the actually client's timezone.
 * @param {date} date America/Toronto date
 */
export const dateByCusTz = (date) => {
    let dateConverted = null
    if (date) {
        let dateTorontoTz = dayjs.tz(date, ['MMM DD, YYYY h:mm a', 'MMM DD, YY', 'YYYY-MM-DD hh:mm:ss'], 'America/Toronto')
        const cusTz = dateTorontoTz.clone().tz(dayjs.tz.guess())

        if (cusTz.isValid()) dateConverted = cusTz
    }

    return dateConverted
}

/**
 * @function formatCalendarDate
 * @description Format date in MMM DD, YYYY h:mm pattern.
 * @param {string} date
 */
export const formatCalendarDate = (date) => {
  if (!date) return;
  
  let dateToBeReturned = '';
  const dateConverted = dayjs.tz(date, dayjs.tz.guess());
  
  if (dateConverted.isValid()) {
    dateToBeReturned = dateConverted.format('MMMM D, YYYY  h:mm:ss a');
  }
  
  return dateToBeReturned;
};

/**
 * @function formatCalendarDateOnly
 * @description Format date in MMM DD, YY pattern.
 * @param {string} date
 */
export const formatCalendarDateOnly = (date) => {
    let dateToBeReturned = ''
    if (date) {
        const dateConverted = dateByCusTz(date)
        if (dateConverted && dateConverted.isValid()) dateToBeReturned = dateConverted.format('MMM DD, YYYY')
    }
    return dateToBeReturned
};

export const getOrdinal = (day) => {
  if (!day || day < 1 || day > 31 || typeof +day !== 'number' ) return;
  if (day >= 11 && day <= 13) {
    return 'th';
  }
  const lastDigit = day % 10;
  switch (lastDigit) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    case 31:
      return 'st';
    default:
      return 'th';
  }
}

export function formatHolidayDateOrdinal(dates) {
    // Skip MomentJS ISO format warning during testing
    // moment.suppressDeprecationWarnings = true;
    const formattedDates = dates.filter(date=> dayjs(date).isValid()).map(date => {
      return dayjs(date);
    });

    let result = '';
    for (let i = 0; i < formattedDates.length; i++) {
      const currentDate = formattedDates[i];
      const currentMonth = currentDate.format('MMMM');
      const currentDay = currentDate.format('D');
      const currentOrdinal = getOrdinal(+currentDay);
  
      if (i === 0) {
        result = `${currentMonth} ${currentDay}${currentOrdinal}`;
      } else {
        const previousDate = formattedDates[i - 1];
        const previousMonth = previousDate.format('MMMM');
        if (currentMonth === previousMonth) {
          const connector = i === formattedDates.length - 1 ? ' & ' : ', ';
          result += `${connector}${currentDay}${currentOrdinal}`;
        } else {
          result += ` & ${currentMonth} ${currentDay}${currentOrdinal}`;
        }
      }
    }
  
    return result;
  }

export const extractPredictedDate = ({ paymentTypesDetails, type, serviceType, paymentType, t }) => {
  if (!type || !serviceType || !paymentType || !t) return {};
  if (!paymentTypesDetails || paymentTypesDetails?.length < 1) return {};
  
    const walletFundingTimes = paymentTypesDetails.find(pt => pt.type === paymentType || pt.payment_type === paymentType);
  /**
   * NOTE
   * walletFundingTimes?.timeline - this will handle ExchangeInput on landing page
   * walletFundingTimes?.funding_times - this will handle on payment options
   */
  const fundingTimes = walletFundingTimes?.timeline ? { timeline: walletFundingTimes?.timeline } : (walletFundingTimes?.funding_times || []).find(k => k.service_type === serviceType);
    const { timeline } = fundingTimes || {};
    if (timeline) {
        const timelineValue = timeline[`${type}_timeline`]['predicted_date'];
        const holidayDatesValue = timeline[`${type}_timeline`]['holiday_dates']
        return timelineValue ? {
            translatedDate: t(`common:${serviceType}.${type}_timeline.predicted_date`, { date: timelineValue }),
            formattedDate: timelineValue,
            holidayDates: holidayDatesValue ? formatHolidayDateOrdinal(holidayDatesValue) : null
        } : {};
    }

    return {};
}

export const extractPredictedDay = ({ paymentTypesDetails, type, serviceType, paymentType }) => {
  if (!type || !serviceType || !paymentType) return;
  if (!paymentTypesDetails || paymentTypesDetails?.length < 1) return;
  const walletFundingTimes = paymentTypesDetails.find(pt => pt.type === paymentType || pt.payment_type === paymentType);
  const fundingTimes = (walletFundingTimes?.funding_times || []).find(k => k.service_type === serviceType);
  const { timeline } = fundingTimes || {};

  if (timeline) {
    const timelineValue = timeline[`${type}_timeline`]['predicted_date_time'] || timeline[`${type}_timeline`]['predicted_date'];
    const formattedDate = dayjs(timelineValue).format('MMM D, YYYY');
    return formattedDate
  }

  return;
}

export const toMonthDayByDateTime = (datetime) => {
  if (!datetime) return;
  const dateConverted = dateByCusTz(datetime);
  if (dateConverted && dateConverted.isValid()) 
    return dateConverted.format('MMMM Do');
}

export const extractPredictedDayByDateTime = ({ paymentTypesDetails, type, serviceType, paymentType }) => {
  if (!type || !serviceType || !paymentType) return;
  if (!paymentTypesDetails || paymentTypesDetails?.length < 1) return;

  const walletFundingTimes = paymentTypesDetails.find(pt => pt.type === paymentType || pt.payment_type === paymentType);
  const fundingTimes = (walletFundingTimes?.funding_times || []).find(k => k.service_type === serviceType);
  const { timeline } = fundingTimes || {};
  if (!timeline) return;

  const timelineValue = timeline[`${type}_timeline`]['predicted_date_time'];
  return toMonthDayByDateTime(timelineValue);
}