import React, { useEffect, useRef, useState } from 'react'
import Box from '../../../../../remitbee/components/box/Box'
import classes from './FullWidthSection.module.scss'
import Typography from '../../../../../remitbee/components/typography/Typography'
import theme from '../../../../../remitbee/theme/Theme'
import Divider from '../../../../../remitbee/components/divider/Divider'

const TRANSFER_LIMITS = [
    {
        level: "Level 1",
        daily_limit: "$1000 CAD",
        monthly_limit: "$3000 CAD",
        timeline: "Instant",
        required_docs: "Canadian ID or passport from your origin country."
    },
    {
        level: "Level 2",
        daily_limit: "$3000 CAD",
        monthly_limit: "$25000 CAD",
        timeline: "24 Hours",
        required_docs: "Proof of Address (Bank statement or Utility bill)."
    },
    {
        level: "Level 3",
        daily_limit: "$9000 CAD",
        monthly_limit: "$50000 CAD",
        timeline: "24 Hours – reach out to customer support for express verification if required.",
        required_docs: "Record a video with your ID on our platform. "
    },
    {
        level: "Level 4",
        daily_limit: "$20,000.00 CAD",
        monthly_limit: "$200,000.00 CAD",
        timeline: "24 Hours – reach out to customer support for express verification if required.",
        required_docs: "Complete a questionnaire. Your account must be at least 3 months old to be eligible."
    },
]

const TransferLimitsCard = () => {

    const [maxHeight, setMaxHeight] = useState(0);
    const cardRefs = useRef<any>([]);

useEffect(() => {
    const heights = cardRefs.current.map(card => card.getBoundingClientRect().height);
    const maxCardHeight = Math.max(...heights);
    setMaxHeight(maxCardHeight);
}, [cardRefs?.current?.length]);

  return (
    <Box className={classes['rb-transfer-limits-container']}>
       {TRANSFER_LIMITS.map((e,i) => (
          <Box key={i} componentRef={el => (cardRefs.current[i] = el)} className={classes['rb-transfer-limit-card']} style={{ minHeight: maxHeight}}>
            <div className={classes['rb-limit-header']}>
                <div className={classes['rb-limit-text']}>{i + 1}</div>
                <Typography variant='textXl' weight='bold' color={theme.palette.primary.navy}>{e.level}</Typography>
                <div className={classes['rb-divider']}><Divider /></div>
            </div>
            <div className={classes['rb-limit-footer']}>
              <Box>
                <Typography align='center' variant='textMd' weight='semibold' color={theme.palette.input.body}>{`Daily limit: ${e.daily_limit}`}</Typography>
                <Typography align='center' variant='textMd' weight='semibold' color={theme.palette.input.body}>{`Monthly limit: ${e.monthly_limit}`}</Typography>
              </Box>
              <Typography align='center' variant='textSm'>{`Required Documents: ${e.required_docs}`}</Typography>
              <Typography align='center' variant='textSm'>{`Time: ${e.timeline}`}</Typography>
            </div>
          </Box>
        ))}
    </Box>
  )
}

export default TransferLimitsCard