import React, { useEffect, useState } from 'react'
import classes from './FullWidthSection.module.scss'
import Typography from '../../../../../remitbee/components/typography/Typography'
import theme from '../../../../../remitbee/theme/Theme'
import Box from '../../../../../remitbee/components/box/Box'

import axios from 'axios'
import { formatNumber } from '../../../../../shared/math'

const TopCurrencyPairs = ({ currencyPairsList }) => {

    const [rates, setRates] = useState([]);
    const [loading, setLoading] = useState(true);
    

    const fetchCurrencyValues = async () => {
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_API}/public-services/online-rates-list-history`, {
                    rate_id_list: currencyPairsList.map(e => e.country_id)
            });
            if(data) {
                let mappedData = data.map((e,i) => {
                  if(parseInt(e.rate_id) === parseInt(currencyPairsList[i].country_id)) {
                    return { ...e, onClick: () => window.open(currencyPairsList[i].onClick, '_blank')}
                  } else return e
                })
                setRates(mappedData)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
         fetchCurrencyValues()
    }, [])

  const Card = ({ rate }) => {
    function getPercentageDifference(value1, value2) {
        const difference = value2 - value1;
        const percentage = (difference / value1) * 100;
        const symbol = difference >= 0 ? '↑' : '↓';
        const absolutePercentage = Math.abs(percentage).toFixed(2);
        
        return {text:`${symbol} ${absolutePercentage}%`, status: symbol === '↑' ? 'up' : 'down', value:difference };
    }
    const difference = getPercentageDifference(rate?.previous_day_rate || rate?.current_rate, rate?.current_rate)
    return (
        <div className={classes['rb-top-currency-card']} onClick={rate?.onClick}>
        <Typography variant='text2XL' color={theme.palette.primary.navy} weight='semibold'>{`CAD to ${rate?.currency_code}`}</Typography>
        <Box display='flex' gap={8}>
            <Typography variant='textMd' color={theme.palette.primary.navy}>{formatNumber(rate?.current_rate, { maximumFractionDigits: 2})}</Typography>
            <div className={`${classes['rb-rate-status']} ${classes[`rb-rate-status--${difference?.status}`]}`}><Typography variant='textXs' weight='medium'>{difference?.text}</Typography></div>
        </Box>
      </div>
    )
  }  

  if(loading || !rates.length) return null;
  return (
    <div className={classes['rb-top-currency-pairs-container']}>
      {rates?.map((e,i) => <Card key={i} rate={e} />)}
    </div>
  )
}

export default TopCurrencyPairs